/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { FormattedMessage, navigate } from 'gatsby-plugin-react-intl';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import DangerButton from '../buttons/DangerButton';
import PrimaryButton from '../buttons/PrimaryButton';
import { isBrowser } from '../common/helpers';
import FormField from '../form/FormField';
import { rowStyle } from '../form/FormStyles';
import ErrorPopup from '../popup/ErrorPopup';
import PrimaryPopup from '../popup/PrimaryPopup';
import SuccessPopup from '../popup/SuccessPopup';
import DangerPopup from '../popup/DangerPopup';
import { WarehouseStatus } from '../warehouses/types';
import {
  changeWarehouseName,
  getWarehouseSucceeded,
  resetWarehouseDetails,
  updateWarehouse,
  resetError,
  WarehouseAction,
  changeWarehouseAction,
} from './redux/actions';

export default function WarehouseDetailsForm() {
  const dispatch = useDispatch();
  const {
    error,
    isWarehouseUpdated,
    nameError,
    loading,
    isFormChanged,
    warehouse,
    warehouseAction,
  } = useSelector((state: RootState) => state.warehouseReducer);

  const [isConfirmationPopupVisible, setConfirmationState] = useState(false);
  const [isDeactivatePopup, setDeactivatePopup] = useState(false);
  const [isActivatePopup, setActivatePopup] = useState(false);

  const warehouseDetails =
    isBrowser &&
    JSON.parse(localStorage.getItem('warehouse-details') as string);

  useEffect(() => {
    if (!warehouseDetails) {
      navigate('/warehouses');
    }

    dispatch(getWarehouseSucceeded(warehouseDetails));

    return () => {
      dispatch(resetWarehouseDetails());
      isBrowser && localStorage.removeItem('warehouse-details');
    };
  }, []);

  function onInputChange(e) {
    const { value } = e.target;

    dispatch(changeWarehouseName(value));
  }

  const errorMessage = error && formatMessage(error.code);

  function getSuccessMessage() {
    switch (warehouseAction) {
      case WarehouseAction.DEACTIVATE:
        return formatMessage('warehouseDeactivated');

      case WarehouseAction.ACTIVATE:
        return formatMessage('warehouseActivated');

      default:
        return formatMessage('warehouseSaved');
    }
  }

  const isInvalid = nameError || !warehouse?.name;

  function openConfirmationPopup() {
    setConfirmationState(true);
  }

  function closeConfirmationPopup() {
    setConfirmationState(false);
  }

  function onSubmit() {
    dispatch(updateWarehouse(warehouse));

    setConfirmationState(false);
  }

  function onSubmitDeactivate() {
    dispatch(
      updateWarehouse({
        id: warehouse.id,
        status: WarehouseStatus.DISABLED,
      }),
    );
    dispatch(changeWarehouseAction(WarehouseAction.DEACTIVATE));

    setDeactivatePopup(false);
  }

  function onSubmitActivate() {
    dispatch(
      updateWarehouse({
        id: warehouse.id,
        status: WarehouseStatus.ENABLED,
      }),
    );
    dispatch(changeWarehouseAction(WarehouseAction.ACTIVATE));

    setActivatePopup(false);
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Row {...rowStyle} align="baseline">
        <FormField
          colSize="7"
          error={nameError}
          label={formatMessage('warehouseName')}
          inputValue={warehouse?.name}
          inputType="text"
          inputName="name"
          placeholder={formatMessage('warehouseNamePlaceholder')}
          onChange={onInputChange}
          required={true}
          tabIndex="1"
        />
      </Row>
      <Row p={{ t: '1.75rem', b: '1rem' }}>
        <Col>
          <PrimaryButton
            icon="Checked"
            size="lg"
            disabled={isInvalid || loading || !isFormChanged}
            onClick={() => openConfirmationPopup()}
            loading={loading}
          >
            <FormattedMessage id="save" />
          </PrimaryButton>
        </Col>
      </Row>
      <Row>
        <Col>
          {warehouse?.status === WarehouseStatus.ENABLED ? (
            <DangerButton
              size="lg"
              icon="Delete"
              onClick={() => setDeactivatePopup(true)}
            >
              <FormattedMessage id="deactivate" />
            </DangerButton>
          ) : (
            <PrimaryButton size="lg" onClick={() => setActivatePopup(true)}>
              <FormattedMessage id="activate" />
            </PrimaryButton>
          )}
        </Col>
      </Row>
      {isDeactivatePopup && (
        <DangerPopup
          title={formatMessage('deactivateWarehouseConfirmation')}
          onClose={() => setDeactivatePopup(false)}
          onSubmit={onSubmitDeactivate}
          cancelText={formatMessage('cancel')}
          submitText={formatMessage('deactivate')}
        >
          <Div>
            <Row m={{ t: '20px' }}>{warehouse?.name}</Row>
          </Div>
        </DangerPopup>
      )}
      {isActivatePopup && (
        <PrimaryPopup
          title={formatMessage('activateWarehouseConfirmation')}
          onClose={() => setActivatePopup(false)}
          onClick={onSubmitActivate}
          cancelBtnText={formatMessage('cancel')}
          btnText={formatMessage('activate')}
        >
          <Div>
            <Row m={{ t: '20px' }}>{warehouse?.name}</Row>
          </Div>
        </PrimaryPopup>
      )}
      {isConfirmationPopupVisible && (
        <PrimaryPopup
          title={formatMessage('saveChangesQuestion')}
          cancelBtnText={formatMessage('cancel')}
          onClose={closeConfirmationPopup}
          btnText={formatMessage('save')}
          onClick={onSubmit}
        >
          <Row>
            <Col>
              <Text>{warehouse?.name}</Text>
            </Col>
          </Row>
        </PrimaryPopup>
      )}
      {isWarehouseUpdated && !isInvalid && (
        <SuccessPopup
          onSubmit={() => {
            navigate('/warehouses');
          }}
          submitText={formatMessage('close')}
          title={getSuccessMessage()}
        >
          {warehouse?.name}
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup
          onSubmit={() => dispatch(resetError())}
          submitText={formatMessage('close')}
          title={formatMessage('errorOccurred')}
        >
          {errorMessage}
        </ErrorPopup>
      )}
    </form>
  );
}
